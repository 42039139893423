import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES


//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'
import AdminHeader from '../partils/AdminHeader'

export default function AdminLead(){
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Log',
        mainstring:'nome',
        url:'/api/log/',
        url2:'',
        search:'/api/log/search/status',
        //urlOptions1:'/api/produtocategoria',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    ////    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
        status:''
    }
    const [openNew,setOpenNew]=useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues]=useState(initialValues) // INICIANDO UM STATE
    const [data, setData] = useState([])
    //const[dataInput,setDataInput]=useState([{"id_produto_categoria":0}])
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url).then(resp=>{
            setData(resp.data)})
        
    }
    //console.log(data)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const {name, value} = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]:value }); //SETANDO O VALUE EM NAME
    }
    
    const exclui = function(id){//EXCLUI
        axios.delete(config.url + id).then(()=>{
            rec();
            
        })
    }
    function onSubmitSearch(ev){//PESQUISA
        ev.preventDefault();
        console.log(values)
        axios.post(config.search, values).then(respp=>{
            setData(respp.data)       
            console.log(respp)
        })
    }
    //COMPONENTES DE LOOP==============================
    //    id_leads nome	telefone  email	porte	createdAt	updatedAt	
    const final = data.map(
        namesString =>
            <div className='w100 h4 row ma5 between linha'  key={namesString.id_log}>
                <div className='w10 cell-tamanho-metade30 fb'>{namesString.method}</div> 
                <div className='w5 cell-tamanho-metade30 fb'>{namesString.status}</div>
                <div className='w10 cell-texto-p c4 cell-tamanho-metade30'>{namesString.responseTime}ms</div>
                <div className='w30 cell-tamanho cell-margin5 c4'>{namesString.url}</div>
                <div className='w5 cell-tamanho-metade30'>{namesString.id_log}</div>
                <div className='w20 cell-texto-p cell-tamanho-metade30 c4'>
                {new Date(namesString.createdAt).toLocaleString('pt-BR', {
                    timeZone: 'America/Sao_Paulo', // Substitua pelo fuso horário que você deseja
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit'
                })}
                </div>
                <button className='w5 btn04 ' onClick={()=>exclui(namesString.id_log)}>X</button>
                <div className='w10 c4 w100 fs06em'>{namesString.device}</div>
                <div className='w10 c4 w100 fs08em'>{namesString.nome}</div>
            </div>
    )
    
    return(
        <>
         <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center celular">
                    
                    <div className="w100 row between cell-tamanho celular">
                        <div className="h100 w100 cont-circ3  column cell-tamanho90 cell-paddingcb10">
                        <h5 className='w90'>Pesquisar {config.pageName}</h5>
                        <form className=' w90 row center' onSubmit={onSubmitSearch} autoComplete='off'>
                            <div className='w80'>
                                <input type="text" name="status" onChange={onChange}/>
                            </div>
                            <div className='w20'>
                                <input type="submit" value="Pesquisar"/>
                            </div>
                        </form>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            <div className='row between w100 h5 cell-none'>
                              
                            </div>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}

