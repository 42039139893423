import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
//INPORTAÇOES DE ICONES E IMAGENS

import MenuPainelAdmin from '../partils/AdminMenuPainel'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Select from 'react-select';
import Icon1de3 from '../../acets/img_icon/1de3.svg'
import Icon2de3 from '../../acets/img_icon/2de3.svg'
import Icon3de3 from '../../acets/img_icon/3de3.svg'

import TenancyColor from '../midllers/TenancyColor'
import AdminHeader from '../partils/AdminHeader'

export default function AdminVendaRapida() {
    const { param1 } = useParams(); // para acessar rota de editer
    //CONGIGURAÇÕES=============================
    const config = {
        pageName: 'Orçamento',
        mainstring: 'nome',
        url: '/api/cliente/',
        url2: '',
        search: '/api/cliente/search/',
        urlOptions1: '/api/produto_servico',
        urlOptions2: '/api/cliente',
        urlOptions3: '/api/clientefornecedor',
        urlOptions4: '/api/baixas_orcamento',

        params: '',
        messegerError: 'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
    //INICIANDO CICLO DE ESTADOS
    useEffect(() => {
        if (param1) {
            setOpemcliente(false);
            setOpemProduto(true);
            setPedidoId(param1)
            axios.get("api/pivo_orcamento_produto/"+param1).then(resp=>{
                setDatalistItens(resp.data)
                //console.log(resp.data)
            })
          }
        rec();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [param1])
    //STATES======================================
    const initialValues = { // DEFININDO UMA CONST PARA O STATE INICIAL 
        nome:'',placa:'',carro:'',endereco:'SM',telefone:'',email:'',status:1,observacao:'',codigo_acess:2,quantidade:'',id_produto_servico:'',id_cliente:'',id_clientefornecedor:'',valor:0,forma_pgto:'',preco:'', descricao:'',tipo:'2',foto_antes:'',foto_depois:'',desconto:''
    }
    //const navigate = useNavigate();

    //const [openNew, setOpenNew] = useState(false)// ABRIR TELA DE CADASTRAR E EDITAR
    const [values, setValues] = useState(initialValues) // INICIANDO UM STATE
    const [dataInput, setDataInput] = useState([])//Clientes
    const [optionInput2, setOptionInput2] = useState([])//Produtos
    const [optionInput3, setOptionInput3] = useState([])//Clientes fornecedores

    const [datalistItens, setDatalistItens] = useState([])
    const [datalistItensBaixas, setDatalistItensBaixas] = useState([])
    const [pedidoId, setPedidoId] = useState()
    const [cadcliente, setCadcliente] = useState()
    const [opemcliente, setOpemcliente] = useState(true)
    const [opemProduto, setOpemProduto] = useState(false)
    const [opemPedido, setOpemPedido] = useState(false)
    const [newpreco, setNewpreco] = useState(0)
    const [modalnewpreco, setModalnewpreco] = useState(false)
    const [cadProdAvulsoModal, setCadProdAvulsoModal] = useState(false)
    const [confirmexc,setConfirmexc]=useState(false)
    //FUNÇÕES=====================================
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        
        //setDataInput /pivo_pedido_cliente
        axios.get(config.urlOptions1).then(resp=>{
            setDataInput(resp.data)
        })
        pedidoId && axios.get("api/pivo_orcamento_produto/"+pedidoId).then(resp=>{
                setDatalistItens(resp.data)
                //console.log(resp.data)
        })
        axios.get(config.urlOptions2).then(resp=>{
            setOptionInput2(resp.data)
        })
        axios.get(config.urlOptions3).then(resp=>{
            setOptionInput3(resp.data)
        })
        axios.get(`${config.urlOptions4}/${pedidoId}`).then(resp => {
            setDatalistItensBaixas(resp.data);
        });
        
    }
    
    //console.log(dataInput)
    function onChange(ev) {//ALTERAR OS STATES DOS INPUTS
        const { name, value } = ev.target; //ESTRAIR O NAME E VALUE DO EV
        setValues({ ...values, [name]: value }); //SETANDO O VALUE EM NAME
    }
    const handleSelectChange = (selectedOption) => {
        // id_cliente
        axios.get(config.url + selectedOption.value).then(resp => {
          if (resp.data.length > 0) {
            const newData = resp.data[0]; // Pega o primeiro objeto do array
            setValues({
              id_cliente:  newData.id_cliente || '',
              nome: newData.nome || '',
              placa: newData.placa || '',
              carro: newData.carro || '',
              endereco: newData.endereco || 'SM',
              telefone: newData.telefone || '',
              email: newData.email || '',
              status: newData.status || 1,
              observacao: newData.observacao || '',
              codigo_acess: newData.codigo_acess || 2,
              quantidade: ''
            });
          }
        });
      };
      //values.id_produto_servico
    const handleSelectChangeProduto = (selectedOption) => {
        setValues({ ...values, id_produto_servico: selectedOption.value });
      };
    const handleSelectChangeFornecedor = (selectedOption) => {
        setValues({ ...values, id_clientefornecedor: selectedOption.value });
      };
      
      function onSubmit1(ev) {
        ev.preventDefault(); // Cadastrando cliente seee
        if(values.id_cliente===''&&values.nome===''){
            alert("ATENÇÃO! Preencha todos os campos")
        }
        else{
            if (values.id_cliente) {
            axios.post("api/orcamento/", {//CADASTRA ORÇAMENTO quando o cliente é selecionado no input
                status: 1,
                data: '',
                total: '',
                tenancyId: '',
                id_cliente: values.id_cliente
            }).then(resp => {
                setPedidoId(resp.data.id_orcamento_venda);
                setOpemcliente(false);
                setOpemProduto(true);
            });
            } else {
            axios.post(config.url, values).then(resp => {//CADASTRA CLIENTE com os dados preenchidos
                rec();
                //console.log(resp.data+"-------------")
                axios.post("api/orcamento/", {//CADASTRA ORÇAMENTO
                status: 1,
                data: '',
                total: '',
                tenancyId: '',
                id_cliente: resp.data.id_cliente
                }).then(resp => {
                setPedidoId(resp.data.id_orcamento_venda);
                setOpemcliente(false);
                setOpemProduto(true);
                });
            });
            }
        }
      }

    //id_orcamento_venda status cliente_id datatotal tenancyId
    const navigate = useNavigate();
    function salvarfoto(ev) {
        ev.preventDefault(); // Previne o comportamento padrão do form (recarregar a página)
    
        const formData = new FormData();
    
        // Verifica se uma nova imagem foi anexada antes de adicionar ao FormData
        if (values.foto_antes && typeof values.foto_antes !== 'string') {
            console.log('Adicionando foto antes:', values.foto_antes);
            formData.append('foto_antes', values.foto_antes);
        }
    
        if (values.foto_depois && typeof values.foto_depois !== 'string') {
            console.log('Adicionando foto depois:', values.foto_depois);
            formData.append('foto_depois', values.foto_depois);
        }
    
        // Adiciona outros campos ao FormData
        console.log('Adicionando observação:', values.observacao);
        formData.append('observacao', values.observacao);
        formData.append('id_orcamento_venda', pedidoId); // Adiciona o ID do orçamento/venda
    
        // Faz a requisição para enviar os dados
        axios.post(`/api/orcamento/foto/${pedidoId}`, formData, {
            headers: {
                "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                'enctype': 'multipart/form-data'
            }
        })
        .then(resp => {
            console.log('Resposta do servidor:', resp.data);
            settotal();
            alert("Comentário ou foto salvo com sucesso!");
        })
        .catch(err => {
            console.error('Erro ao enviar as imagens:', err);
        });
    }
    
    
    // SALVA BAIXAS NO ORCAMENTO
    function onSubmit2(ev) { 
        ev.preventDefault();
        axios.post('api/baixas_orcamento', {
            id_orcamento_venda: pedidoId,
            status: values.status,
            valor:values.valor,
            forma_pgto:values.forma_pgto,
            data: values.data,
            id_cliente: values.id_cliente,
            total: values.total,
            codigo_acess: values.codigo_acess,
            desconto: values.desconto,
            observacao: values.observacao
        })
        .then(() => {
            setValues(initialValues);
            rec();
            //navigate('/adminpedido');
        })
        .catch(error => {
            console.error("Error submitting:", error);
        });
    }
    
    
    function onSubmit3(ev) {
        ev.preventDefault();//cadastrando produto id_pedido id_produto PIVO
        console.log(values)
        axios.post('/api/pivo_orcamento_produto', {
            id_orcamento_venda:pedidoId,
            id_produto_servico:values.id_produto_servico,
            quantidade: parseInt(values.quantidade),
            id_clientefornecedor:values.id_clientefornecedor
        })
        .then(() => {
            setValues(initialValues);
            rec();
        })
    }
    function onSubmit4(ev){//PESQUISA
        ev.preventDefault();
        axios.post('/api/produto_servico/', values).then(respp=>{
            setValues(initialValues);
            rec();      
        })
    }
    
    function excluir(id){//EXCLUI
        axios.delete('api/pivo_orcamento_produto/' + id).then(()=>{
            rec();
        })
    }
    function excluirBaixa(id){//EXCLUI
        axios.delete('api/baixas_orcamento/' + id).then(()=>{
            rec();
        })
    }

    // BIND Produtos option
    const filteredData = dataInput.filter(repsValues => repsValues.status !== 6);
    const optionInput = filteredData.map(repsValues => ({
        value: repsValues.id_produto_servico,
        label: repsValues.descricao+" - "+repsValues.preco , // Use a propriedade apropriada para a descrição do cliente
    }));
    
    // BIND Clientes option
    const filteredData2 = optionInput2.filter(repsValues => repsValues.status !== 6);
    const optionInput2Bind = filteredData2.map(repsValues => ({
      value: repsValues.id_cliente,
      label: [repsValues.nome, repsValues.carro&&' - '+repsValues.carro], // Use a propriedade apropriada para a descrição do cliente
    }));
     // BIND Clientes Fornecedores option
     //console.log(datalistItens)
     const filteredData3 = optionInput3.filter(repsValues => repsValues.status !== 6);
     const optionInput3Bind = filteredData3.map(repsValues => ({
       value: repsValues.id_cliente,
       label: repsValues.cliente.nome+" F: "+ repsValues.cliente.id_cliente
       , // Use a propriedade apropriada para a descrição do cliente
     }));
    // BIND itens

     function updatePrice(){
        //newpreco = MODAL
        axios.put('/api/pivo_orcamento_produto', {
            id_pivo_orcamento_produto:newpreco,
            preco:values.preco
        })
        .then((resp)=>{
            setNewpreco(0)
            rec();
            //console.log('a'+values.preco+'t')
        })
     }

    //console.log(datalistItens)
    const itensLoop = datalistItens.map(repsValues => (
        <div key={repsValues.id_pivo_orcamento_produto} className="w100 h5 row between linha">
            <label className='w5 cell-text-smile08'>{repsValues.quantidade}</label> 
            <label className='w50 cell-text-smile08'>{repsValues.produto_servico.descricao}</label>
            <label className='w15 cell-text-smile08 linha pp' onClick={()=>setNewpreco(repsValues.id_pivo_orcamento_produto)}>{repsValues.preco}</label>
            <label className='w10 cen cell-text-smile08'>F:{repsValues.id_clientefornecedor}</label>
            <label className='w15 cell-text-smile08 pp fim' onClick={()=>setConfirmexc(repsValues.id_pivo_orcamento_produto)}>Excluir</label>
            {confirmexc === repsValues.id_pivo_orcamento_produto && (
                <div className='w100 row between'>
                    <div className='w50 column center b11 pcb7 pp fw500' onClick={()=>excluir(repsValues.id_pivo_orcamento_produto)}>
                        Sim
                    </div>
                    <div className='w50 column center b28 pcb7 pp fw500' onClick={()=>setConfirmexc(null)}>
                        Não
                    </div>
                </div>
            )}
        </div>
    ));
    //id_baixa
    // BIND itens Baixas
    const itensLoopBaixas = datalistItensBaixas.map(repsValues=>
        <div key={repsValues.id_baixa} className="w100 h5 row between linha">
            <label className='w25 cell-text-smile08'>{repsValues.forma_pgto}</label> 
            <label className='w15 cell-text-smile08'>{repsValues.valor_baixa}</label>
            <label className='w25 cell-text-smile08'>{repsValues.createdAt.substring(0, 10)}</label>
            <label className='w15 cell-text-smile08 pp'onClick={()=>excluirBaixa(repsValues.id_baixa)}>Excluir</label>
        </div>
    )

    //console.log(datalistItensBaixas)
    function calcularTotal(itens) {//total com quantidade
        return itens.reduce((total, item) => total + parseFloat(item.preco) * item.quantidade, 0);
      }
    function calcularTotalBaixas(itens) {
        return itens.reduce((valor_baixa, item) => valor_baixa + parseFloat(item.valor_baixa), 0);
      }

    const totalItens = calcularTotal(datalistItens);
    const totalItensBaixa = calcularTotalBaixas(datalistItensBaixas);

    function settotal(){
        axios.put('api/orcamento/',{
            id_orcamento_venda:pedidoId,
            total:totalItens
        }).then(resp=>{
           setValues(resp.data)
        })
    }
   // console.log(values)
    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
      
        if (file) {
          setValues(prevValues => ({
            ...prevValues,
            [field]: file // Adiciona o arquivo diretamente
          }));
        }
    };
    
      
//const corBaseTenancy = TenancyColor().backgroundColor
    //COMPONENTES DE LOOP==============================
   // console.log(values)
    return (
        
        <>
            <section className='h100 row between b31'>
           
           
                {newpreco?
                <div className='w100 h100 z17 column center fundo-opaco posifixedinicio'>
                    <div className='b2 h10 w20 row center cont-circ3 p20 cell-tamanho75'>
                        <h5 className='w100'>Editar Preço</h5>
                        <label className='w100' >Digite o novo preço</label>
                        <input type="number" name="preco" onChange={onChange} value={values.preco} autoComplete='off'/>
                        <div className='w100 row between'>
                            <button className='btn05' onClick={()=>setNewpreco(0)}>Cancelar</button>
                            <button className='' onClick={()=>updatePrice()}>Salvar</button>
                        </div>
                    </div>
                </div>
                :<label></label>
                }
               
            
               <MenuPainelAdmin/>
                <div className="h100 w80 cell-tamanho row center  celular ma25b0">
                    
                    <div className="w90 row between cell-tamanho celular cell-paddingcb10 ma25b0 ">
                    <div className="h20 w100 b2 cont-circ3 b2 borda1 column  cell-tamanho90 ma25b0">
                            <div className='w100 b36 row center'>
                                <h5 className='w90 c3'>Novo {config.pageName}</h5>
                                       
                            </div>
                            <div className='w90 h20 b2'>
                                
                                {opemcliente&&
                                <>
                                <img src={Icon1de3} className="w10 ma10b0" alt="1 de 3"></img>
                                {optionInput2Bind?<>
                                <h5>Pesquisar cliente Existente</h5>
                                {!cadcliente&&<Select options={optionInput2Bind} onChange={handleSelectChange} className='z1' />}
                                </>:<label>Carregando...</label>}
                                <div className='column center h5'>Ou</div>  
                                <form onSubmit={onSubmit1} autoComplete='off'>
                                    <div className='row h10' onClick={()=>setCadcliente(true)&setValues(initialValues)}>
                                        <h5>Cadastro de cliente </h5> 
                                        <label> - Clique aqui</label>
                                    </div>
                                   {cadcliente&&
                                    <div className=''>
                                        <div>
                                            <label>Nome:</label>
                                            <input type="text" name="nome" onChange={onChange} value={values.nome} autoComplete='off'></input>
                                        </div>
                                        <div>
                                            <label>Placa:</label>
                                            <input type="text" name="placa" onChange={onChange} value={values.placa} autoComplete='off'></input>
                                        </div>
                                        <div>
                                            <label>Carro:</label>
                                            <input type="text" name="carro" onChange={onChange} value={values.carro} autoComplete='off'></input>
                                        </div>
                                        <div>
                                            <label>Telefone:</label>
                                            <input type="number" name="telefone" onChange={onChange} value={values.telefone} autoComplete='off'></input>
                                        </div>
                                    </div>
                                    }
                                    <div className='cell-margin5 ma10b0'>
                                        <input type="submit" value="Proximo" className='button'></input>
                                    </div>
                                </form>
                                </>
                                }
                                { opemProduto&&
                                <div className='w100'>
                                    <img src={Icon2de3} className="w10 ma10b0" alt="2 de 3"></img>
                                    <div className='row between'>
                                        <h5 className=''>Adicionar produtos e serviços ao orçamento</h5>
                                        <label className='pcb10 fs08em c4 cell-none'>Adicione os produtos do orçamento, preencha todos os campos e se não houver o produto cadastrado, clique em: "Cadastrar produto Avulso" mas se deseja inserir um produto para controlar o  estoque clique no menu "Produtos e Serviços"</label>
                                    </div>
                                    <form className='w100 Icon3de3' onSubmit={onSubmit3}>
                                        <div className='row between w100 celular'>
                                            <div className='w40 cell-tamanho'>
                                                <label className='fw500 fs08em'>Produto:</label>
                                                <Select options={optionInput}style={{height: '6vh', zIndex: 1, margin: '0px', padding:'0px'}}  onChange={handleSelectChangeProduto} />  
                                            </div>
                                            {/*<div className='w10 cell-tamanho'>
                                                <label>Preço:</label>
                                                <input type="number" name="quantidade" onChange={onChange} value={values.quantidade} min="1"></input>
                                            </div>*/}
                                            <div className='row around cell-tamanho w40'>
                                                <div className='w45 cell-tamanho-metade'>
                                                    <label className='fw500 fs08em'>Quantidade:</label>
                                                    <input type="number" name="quantidade" onChange={onChange} value={values.quantidade} min="1"></input>
                                                </div>
                                                <div className='w45 cell-tamanho-metade'>
                                                    <label className='fw500 fs08em'>Fornecedor:</label>
                                                    <Select options={optionInput3Bind} className='z1' onChange={handleSelectChangeFornecedor} />  
                                                </div>
                                            </div>
                                            <div className='w20 cell-tamanho'>
                                                <label className='fw500 fs08em'>Ação:</label>
                                {
                                    //`${corBaseTenancy}`
                                }
                                                <input
                                                    type="submit"
                                                    value="Adicionar Item"
                                                    style={{
                                                        backgroundColor: values.id_produto_servico && values.quantidade && values.id_clientefornecedor
                                                        ? "#c90c2f"
                                                        : "#c1c1c1",
                                                    }}
                                                    disabled={!values.id_produto_servico || !values.quantidade || !values.id_clientefornecedor}
                                                    />
                                                
                                            </div>
                                        </div>
                                        
                                    
                                            
                                            
                                        
                                    </form>
                                    <label className='fw500'>Ou</label>
                                    
                                    <form className='w100 row between' onSubmit={onSubmit4}>
                                        <div className='column start'>
                                            <h5 className='w100 pp' >Cadastrar produto Avulso</h5>
                                            <label className='w100  fs08em ' onClick={()=>setCadProdAvulsoModal(true)}>
                                                Se tentou adicionar o produto e ele não existe, <b className='pp'>clique aqui</b> para cadastrar na lista de produtos e serviços.</label>
                                        </div>
                                        
                                        {cadProdAvulsoModal&&<>
                                        <label className='pcb10 fs08em w100 c4 cell-none'>Após cadastrar o produto, pesquise ele no input de produto acima e preencha a quantidade e fornecedor.</label>
                                        <div className='w40 cell-tamanho'>
                                            <label className='fw500 fs08em'>Descrição:</label>
                                            <input type="text" name="descricao" onChange={onChange} value={values.descricao}></input>
                                        </div>
                                        <div className='w35 cell-tamanho'>
                                            <label className='fw500 fs08em'>Preço:</label>
                                            <input type="number" name="preco" onChange={onChange} value={values.preco} min="1"></input>
                                        </div>
                                        <div className='w20 cell-tamanho'>
                                            <label className='fw500 fs08em'>Ação:</label>
                                            
                                            <input
                                                type="submit"
                                                value="Cadastrar Item"
                                                style={{
                                                    backgroundColor: values.preco||values.preco
                                                    ? "#c90c2f"
                                                    : "#c1c1c1",
                                                }}
                                                disabled={!values.preco||!values.preco}
                                                
                                            />
                                        </div>
                                        </>}
                                    </form>
                                    
                                    <div className='w100 column ma10b0'>
                                        <div className="w100 h5 row between linha b35">
                                            <label className='w5  fw500 cell-none fs08em c2'>Qnt</label> 
                                            <label className='w50  fw500 fs08em c2'>Descrição</label>
                                            <label className='w15  fw500 fs08em c2'>Preço</label>
                                            <label className='w10 cen  fw500 cell-none fs08em c2'>Fornecedor</label>
                                            <label className='w15  fw500 fs08em fim c2'>Ação</label>
                                        </div>
                                       {itensLoop}
                                       <h5 className='w100 fim fw500 '>Total R$: {totalItens}</h5>
                                    </div>
                                    <button className='w100 ma25b0 button' onClick={()=>setOpemPedido(true) & setOpemProduto(false) & settotal() & rec()} >Registrar total</button>
                                    
                                </div>
                                }
                                {opemPedido&&
                                <div className='row between celular'>
                                <form onSubmit={onSubmit2} autoComplete='off' className='w100 cell-tamanho'>
                                    <img src={Icon3de3} className="w10 ma10b0" alt="3 de 3"></img>
                                    <div className='row between w100'>
                                    <div className='row w100 h10'>
                                        <h5 className='w50 cell-tamanho'>Pagamento</h5>
                                        <div className='w50 row cell-tamanho'>
                                            <div className='column w30 '>
                                                <label className='fw500'>Total</label>
                                                <label className='fs1p3em'>R$:{totalItens}</label>
                                            </div>
                                            <div className='column w30'>
                                                <label className='fw500'>Pago</label>
                                                <label className='fs1p3em'>R$:{totalItensBaixa}</label>
                                            </div>
                                            <div className='column w30'>
                                                <label className='fw500'>Em aberto</label>
                                                <label className='fs1p3em'>R$:{totalItens-totalItensBaixa}</label>
                                            </div>
                                        </div>
                                    </div>
                                        
                                        <div className='w40 cell-tamanho'>
                                            <label>Pagamento:</label>
                                            <select type="text"  name="forma_pgto"  onChange={onChange} >
                                                <option value={""}> Selecione</option>
                                                <option value={"DINHEIRO"}> Dinheiro</option>
                                                <option value={"CART.CRED"}> Cartao de credito</option>
                                                <option value={"CART.DEBT"}> Cartao de Debito</option>
                                                <option value={"PIX"}> Pix</option>                                   
                                            </select>
                                        </div>
                                        <div className='w35 cell-tamanho-metade50'>
                                            <label>Valor:</label>
                                            <input type="number" name="valor" onChange={onChange} value={values.valor} min={0}></input>
                                        </div>
                                        <div className='w20 cell-tamanho-metade'>
                                            <label>Ação:</label>
                                           
                                            <input 
                                                type="submit" 
                                                value="Salvar pagam." 
                                                style={{
                                                    backgroundColor: values.valor && values.forma_pgto
                                                    ? "#F3123C"
                                                    : "#c1c1c1",
                                                }}
                                                disabled={!values.valor || !values.forma_pgto}
                                            />
                                            
                                        </div>
                                    </div>
                                    
                                    <div className="w100 h5 row between linha b35">
                                            <label className='w25  fw500 cell-none fs08em c2'>Forma de pg</label> 
                                            <label className='w15  fw500 fs08em c2'>Valor</label>
                                            <label className='w25  fw500 fs08em c2'>Data</label>
                                            <label className='w15   fw500 fs08em c2'>Ação</label>
                                    </div>
                                   
                                    {itensLoopBaixas}
                                    <div className='cell-margin5 ma10b0'>
                                    <Link to="/adminpedido" className='w100' >
                                        <button className='w100' style={{backgroundColor: TenancyColor().backgroundColor}}>Finalizar</button>
                                    </Link>
                                    </div>
                                </form>
                                {/*
                                <form 
                                    onSubmit={salvarfoto} 
                                    encType="multipart/form-data" // Isso define o tipo de codificação para enviar arquivos
                                    className='w30 row between cell-tamanho'
                                    >
                                    <h5 className='w100'>Fotos do serviço<span className='fs06em'>(opcional)</span></h5>
                                    
                                    <div className='w40 column center'>
                                        <label htmlFor='fotoantes' className='pp'>Foto antes</label>
                                        <input 
                                        id='fotoantes'
                                        type='file' 
                                        onChange={(e) => handleFileChange(e, 'foto_antes')}
                                        style={{ display: 'none' }}
                                        />
                                        {values.foto_antes && typeof values.foto_antes === 'string' && (
                                        <div className='b23 w100'>
                                            <img src={axios.defaults.baseURL + values.foto_antes} className='w100' />
                                        </div>
                                        )}
                                        {values.foto_antes && typeof values.foto_antes !== 'string' && (
                                        <div className='b23 w100'>
                                            <label>{values.foto_antes.name}</label>  Mostra o nome do arquivo selecionado
                                        </div>
                                        )}
                                    </div>

                                    <div className='w40 column center'>
                                        <label htmlFor='fotodepois' className='pp'>Foto depois</label>
                                        <input 
                                        id='fotodepois'
                                        type='file' 
                                        onChange={(e) => handleFileChange(e, 'foto_depois')}
                                        style={{ display: 'none' }}
                                        />
                                        
                                        {values.foto_depois && typeof values.foto_depois === 'string' && (
                                        <div className='b23 w100'>
                                        
                                            <img src={axios.defaults.baseURL+ values.foto_depois} className='w100' />
                                        </div>
                                        )}
                                        {values.foto_depois && typeof values.foto_depois !== 'string' && (
                                        <div className='b23 w100'>
                                            <label>{values.foto_depois.name}</label>  Mostra o nome do arquivo selecionado 
                                        </div>
                                        )}
                                    </div>

                                    <div className='w100'>
                                        <h5>Observações:</h5>
                                        <span className='fs08em'>Visível para o cliente</span>
                                        <input 
                                        type="text" 
                                        name="observacao" 
                                        onChange={onChange} 
                                        value={values.observacao} 
                                        autoComplete='off'
                                        />
                                    </div>

                                    <button 
                                        className='w100 ma10b0' 
                                        style={{ backgroundColor: (!values.foto_antes && !values.foto_depois && !values.observacao) ? '#c1c1c1' : '#0792E3' }} 
                                        disabled={!values.foto_antes && !values.foto_depois && !values.observacao}
                                        type="submit" // Botão de envio deve ser de submit
                                    >
                                        Salvar comentário / Foto
                                    </button>
                                </form>
                                */}

                             </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
               
            </section>
        </>
    )
}

