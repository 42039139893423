
import { useEffect, useState } from 'react'
import axios from 'axios'
//COMPONENTES PARTES
import React, { useMemo } from 'react';
//INPORTAÇOES DE ICONES E IMAGENS
import IconChecked from '../../acets/img_icon/checked.svg'


import MenuPainelAdmin from '../partils/AdminMenuPainel'
import IconNoChecked from '../../acets/img_icon/nochecked.svg'
import IconEdit from '../../acets/img_icon/edit.svg'

import TenancyColor from '../midllers/TenancyColor'
import { useNavigate } from 'react-router-dom';
import AdminHeader from '../partils/AdminHeader'

export default function AdminVendaRealizada(){
    const navigate = useNavigate();
    //CONGIGURAÇÕES=============================
    const config={
        pageName:'Caixa diario',
        mainstring:'nome',
        url:'/api/baixas_orcamento/',
        url2:'',
        search:'/api/pivo_orcamento_produto/search/',
        urlOptions1:'',
        params:'',
        messegerError:'ATENÇÃO - Preencha os campos para salvar os dados ;D',
    }
     //INICIANDO CICLO DE ESTADOS
     useEffect(()=>{
        rec();

        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[])
    //STATES======================================
    //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    const initialValues={ // DEFININDO UMA CONST PARA O STATE INICIAL 
      id_cliente: '',	nome: '',	placa: '',	carro: '',	endereco: '',	telefone: '',	email: '',	status: '',	observacao: '',	codigo_acess: ''
    }
    const [data, setData] = useState([])
    const[showDetalhes,setShowDetalhes]=useState(false)
    //FUNÇÕES=====================================
    const tlocal = localStorage.getItem('keyItemNameg')
    const headers = {
        'Content-Type': 'application/json',
        'token':tlocal,
    };
    function rec() {//CARREGAR ESTADOS E ATUALIZAR CICLO DE COMPONENTES
        axios.get(config.url,{headers}).then(resp=>{
            //console.log(resp.data)
              setData(resp.data)        
        })
    }

    

   /*const final = data.map(namesString=>
    <div key={namesString.id_baixa} className='w100 b2 borda1 column center'>
        <div  className='w90 h10 row between'>
            <label className='w100 fb ma10b0 fs1p3em'>{new Date(namesString.createdAt).toLocaleDateString('pt-BR')}</label>
            <div className='p10 b10 cont-circ'>
                <label className='fs08em fb'>{namesString.forma_pgto}</label>
            </div>
            <label>{namesString.valor_baixa}</label>
         </div>   
    </div>
   )*/
    
    const groupedData = data.reduce((acc, item) => {
        const date = new Date(item.createdAt).toLocaleDateString('pt-BR');
        if (!acc[date]) acc[date] = [];
        acc[date].push(item);
        return acc;
      }, {});
      
      const final = Object.entries(groupedData).map(([date, items]) => {
        const totalDia = items.reduce((sum, { valor_baixa }) => sum + parseFloat(valor_baixa), 0);
      
        return (
          <div key={date} className="w100 b2 borda1 column center ma5">
            <div className='w100 b36 pcb10 row center'>
              <label className="w90 fs1p3em c2 fb">{date}</label>
            </div>
            <div className='w100 b35 column center'>
              <div className='w90 between row pcb3'>
                <label className='fs09em c2'>Forma de pg</label>
                <label className='fs09em c2'>Valor</label>
              </div>
            </div>
            
            
            
            {items.map(namesString => (
              <div key={namesString.id_baixa} className="w90 linha row between">
                <div className="p10 b37 w10 c4 column center cell-tamanho15">
                  <label className="fs06em  fb c3">{namesString.forma_pgto}</label>
                </div>
                <label>R$ {namesString.valor_baixa}</label>
              </div>
            ))}
            <div className='w90'>
                <div className='w10 cell-tamanho row between pc10l5 '>
                    <label className='cell-texto-p c4 cen fs06em row between cell-linha'>Total de recebidos no Dia R$: </label>
                    <label className='fb c4 cen w100 cell-tamanho20 '>{totalDia.toFixed(2)}</label>
                </div>
            </div>
           
          </div>
        );
      });
      
      
   

      //id_cliente	nome	placa	carro	endereco	telefone	email	status	observacao	codigo_acess	tenancyId
    return(
        <>
        
        
        <section className='h100 row between b31'>
            
            <MenuPainelAdmin/>
            <div className="h100 w80 cell-tamanho row center  celular">
                    
                <div className="w100 row between cell-tamanho celular">
                    <div className="h100 w100 cont-circ3 column  cell-tamanho cell-paddingcb10">
                        <h5 className='w90'>{config.pageName}</h5>
                        <label className='w90 c2'>Dinheiro recebido no dia, proveniente dos pagamentos dos orçamentos criados no dia e de pagamentos de orçamentos atrasados.</label>
                        <div className='w90 h20 column'>
                            <label className='w100 ma5 fs1p3em'>{config.pageName} Listados(a)</label>
                            {final}
                        </div>     
                    </div>
                </div>
            </div>
            
        </section>
      </>
    )
}





